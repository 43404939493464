import { gql } from '@apollo/client'
import Avatar from 'components/display/Avatar'
import { H4 } from 'components/Headings'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import {
  ManagedGroupAvatarsFieldsFragmentDoc,
  MentorMatchResponse,
  ResponseFieldsFragment,
} from 'types/graphql'

import styles from './Responses.module.scss'

gql`
  fragment ResponseFields on MentorMatch {
    mentor {
      ...ManagedGroupAvatarsFields
    }
    mentee {
      ...ManagedGroupAvatarsFields
    }
    responses {
      id
      matchingResponse
      menteeResponse
      mentorResponse
      question {
        id
        key
        question(locale: $locale)
      }
    }
  }
  ${ManagedGroupAvatarsFieldsFragmentDoc}
`

type ResponseProps = {
  match: ResponseFieldsFragment
}

const Responses: FC<ResponseProps> = ({ match }) => {
  const { formatMessage } = useIntl()

  if (match.responses.length === 0)
    return (
      <div className="flex flex-col space-y-6">
        {formatMessage({ id: 'matches.noResponses' })}
      </div>
    )
  const responses = match.responses.map((res: any) => {
    return (
      <div key={res.key} className="">
        <H4 className="mb-6">{res.question.question}</H4>
        <UserResponse match={match} userSegment="mentor" responses={res} />
        <UserResponse match={match} userSegment="mentee" responses={res} />
      </div>
    )
  })
  return <div className="flex flex-col space-y-6">{responses}</div>
}

type UserResponseProps = {
  match: ResponseFieldsFragment
  userSegment: 'mentor' | 'mentee'
  responses: MentorMatchResponse
}
const UserResponse: FC<UserResponseProps> = ({
  match,
  userSegment,
  responses,
}) => {
  const user = match[userSegment]
  const response = responses[`${userSegment}Response`]
  const isMulti = response.length > 1

  return (
    <div className="flex mb-4">
      <Avatar {...user?.avatar} className="flex flex-shrink-0 mr-4" />
      <p
        className={`flex flex-col whitespace-wrap ${
          isMulti ? 'justify-start' : 'justify-center'
        }`}
      >
        <ul className="list-style-none pl-0">
          {response.map((res: string, i: number) => (
            <li
              key={i}
              className={
                responses.matchingResponse.includes(res)
                  ? styles.common
                  : styles.uncommon
              }
            >
              {res}
            </li>
          ))}
        </ul>
      </p>
    </div>
  )
}

export default Responses
