import { gql } from '@apollo/client'
import { lastUpdated } from 'lib/matching'
import { VFC } from 'react'
import {
  LastUpdatedFieldsFragment,
  Maybe,
  MemberMatchingFieldsFragment,
} from 'types/graphql'

gql`
  fragment LastUpdatedFields on MentorMatch {
    active
    activatedAt
  }
`

type CompatibilityProps = {
  member: MemberMatchingFieldsFragment
  match?: Maybe<LastUpdatedFieldsFragment>
  expanded?: boolean
}

const Compatibility: VFC<CompatibilityProps> = ({
  member: { allMatches },
  match,
  expanded,
}) => {
  const date = lastUpdated(allMatches, match, expanded)

  return <div>{date || <span className="text-darkerGray">-</span>}</div>
}

export default Compatibility
